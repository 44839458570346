(function(APP, window, customScrollTo, debounce) {
  'use strict';

  function preventDefault(e) {
    e = e || window.event;
    if (e.preventDefault)
        e.preventDefault();
        e.stopImmediatePropagation();
    e.returnValue = false;
  }

  var lastScrollY = window.pageYOffset;
  var lastItemIndex = null;
  var delay = false;

  /**
   * Section class
   * @param {Object} options
   */
  function Section(options) {
    if (!(this instanceof Section)) return new Section(options);

    if (APP.is.desktop === false || APP.is.ie === true) {
      return false;
    }

    this.config = {
      itemClass: 'js-section-item',
      itemFullClass: 'js-section-item-full',
    };

    this.itemElements = document.querySelectorAll('.' + this.config.itemClass);
    this.itemsPositions = [];

    if (this.itemElements === null) {
      return false;
    }

    this.itemsPositions = this.setElementsPositions(this.itemElements);
    lastItemIndex = this.getIndexByYPosition(window.pageYOffset, 'down');

    document.addEventListener('wheel', this.onScroll.bind(this), {
      passive: false,
    });
    window.addEventListener('keydown', this.onKeydown.bind(this));

    return this;
  }

  Section.prototype.setElementsPositions = function(elements) {
    var positions = [];

    for (var i = 0; i < elements.length; i += 1) {
      var element = elements[i];
      var sizes = element.getBoundingClientRect();

      positions[i] = {
        'top': Math.round(sizes.top + window.pageYOffset),
        'bottom': Math.round(sizes.bottom + window.pageYOffset),
      };
    }

    return positions;
  };

  Section.prototype.onScroll = function(event) {
    // Hack to increase performance
    if(delay) return;
    delay = true;
    setTimeout(function(){delay = false}, 200);

    var deltaY = event.deltaY;
    if (customScrollTo.isScrolling === true) {
      preventDefault(event);
      return false;
    }

    var direction;
    if (window.pageYOffset > lastScrollY) {
      direction = 'down';
    } else if (window.pageYOffset < lastScrollY) {
      direction = 'up';
    } else {
      return false;
    }

    lastScrollY = window.pageYOffset;

    var nextSection = this.getNextSection(lastScrollY + deltaY, direction, 0);
    if (nextSection.element === undefined) {
      preventDefault(event);
      return false;
    }

    if ((nextSection.index === lastItemIndex) || nextSection.element.classList.contains(this.config.itemFullClass) === false) {
      preventDefault(event);
      lastItemIndex = nextSection.index;
      return false;
    }

    // Prevent change section if low delta
    if (Math.abs(deltaY) < 5) {
      preventDefault(event);
      return false;
    }

    return this.goTo(nextSection.element, function() {
      if (lastItemIndex !== nextSection.index) {
        lastItemIndex = nextSection.index;
      }

      lastScrollY = lastScrollY + deltaY;
    });
  };

  Section.prototype.onKeydown = function(event) {
    var scrollY = window.pageYOffset;
    var deltaY = 0;
    var direction;

    // Arrow down or spacebar
    if (event.keyCode == '32' || event.keyCode == '40') {
      deltaY = 10;
      direction = 'down';
    // Arrow up
    } else if (event.keyCode == '38') {
      deltaY = -10;
      direction = 'up';
    } else {
      return false;
    }

    var nextSection = this.getNextSection(scrollY + deltaY, direction, 0);

    if (nextSection.element === undefined) {
      preventDefault(event);
      return false;
    }

    if ((nextSection.index === lastItemIndex) || nextSection.element.classList.contains(this.config.itemFullClass) === false) {
      // preventDefault(event);
      lastItemIndex = nextSection.index;
      return false;
    }

    return this.goTo(nextSection.element, function() {
      if (lastItemIndex !== nextSection.index) {
        lastItemIndex = nextSection.index;
      }

      scrollY = scrollY + deltaY;
    });
  };

  Section.prototype.getIndexByYPosition = function(YPosition, direction, offset) {
    offset = (typeof offset === 'number') ? offset : 100;

    for (var j = 0; j < this.itemsPositions.length; j += 1) {
      if (
        direction === 'down' &&
        YPosition + window.innerHeight > (this.itemsPositions[j].top + offset) && YPosition + window.innerHeight < (this.itemsPositions[j].bottom + offset)
      ) {
        return j;
      }

      if (
        direction === 'up' &&
        YPosition >= (this.itemsPositions[j].top - offset) && YPosition <= (this.itemsPositions[j].bottom - offset)
      ) {
        return j;
      }
    }
  };

  Section.prototype.getNextSection = function(scrollY, direction, offset) {
    var nextItemIndex = null;
    nextItemIndex = this.getIndexByYPosition(scrollY, direction, offset);

    var nextSection = this.itemElements[nextItemIndex];

    return {
      index: nextItemIndex,
      element: nextSection,
    };
  };

  Section.prototype.goTo = function(element, callback) {
    return customScrollTo.init(element, 0.10, callback);
  };

  window.addEventListener('load', function() {
    APP.store.Section = new Section();
  });
})(APP, window, customScrollTo, debounce);
